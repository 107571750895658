@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
body {
  font-family: "Inter", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: rgb(41, 43, 46);
  color: #fff;
}

.container {
  position: relative;
}

.btn.btn-primary {
  background-color: #04b70b;
}

.navbar .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -6px;
}

.card {
  background-color: rgb(45, 48, 51);
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #fafafa;
  margin: 1rem 0;
}
.preLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.6);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}

#web-homepage {
  background: url(/static/media/login-bg-org-min.2beab3b1.jpg) #222 no-repeat center/cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

#web-homepage .card {
  border-radius: 0;
  margin-top: 4rem;
  color: #04b70b;
  display: flex;
  border: 1px solid var(--grey-dim, #666);
  background: rgba(2, 137, 6, 0.1) !important;
  width: 628px;
  padding: 32px;
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
}

#web-homepage .card .btn {
  display: flex;
  width: 104px;
  height: 38px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 0;
  border: 1px solid var(--grey-dim, #666);
}

#web-homepage .card .welcome-content {
  letter-spacing: 2px;
  font-size: 1.5rem;
  font-weight: 600;
}

#web-homepage .card .btn.btn-primary,
#web-homepage .card .btn.btn-secondary {
  background-color: transparent;
}

#web-homepage .card .btn.btn-primary:hover,
#web-homepage .card .btn.btn-secondary:hover {
  background-color: rgba(6, 207, 13, 0.4);
}

.passwordMeter {
  transition: all 0.8s ease-in-out;
}
.checkPasswordMeter {
  width: 0;
  height: 8px;
  border-radius: 32px;
  transition: all 0.8s ease-in-out;
  background-color: #666;
}

.checkPasswordMeter.weak {
  background-color: #dc3545;
  width: 25%;
}
.checkPasswordMeter.moderate {
  background-color: #dfe627;
  width: 50%;
}
.checkPasswordMeter.strong {
  background-color: #31e627;
  width: 100%;
}

